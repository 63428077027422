//import './style.css';
import 'ol/ol.css';
import Graticule from 'ol/layer/Graticule';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import TileDebug from 'ol/source/TileDebug';
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import View from 'ol/View';
import proj4 from 'proj4';
import {
  applyTransform
} from 'ol/extent';
import {
  get as getProjection,
  getTransform
} from 'ol/proj';
import {
  transform
} from 'ol/proj';
import {
  register
} from 'ol/proj/proj4';
import {
  fromLonLat
} from 'ol/proj';
import XYZ from 'ol/source/XYZ';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTile from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import {
  createXYZ
} from 'ol/tilegrid';
import {
   SelectEvent 
} from "ol/interaction/Select";
import {
  altKeyOnly,
  click,
  pointerMove,
  shiftKeyOnly
} from 'ol/events/condition';
// import Collection from 'ol/Collection';
// import Interaction from 'ol/interaction/Interaction';
// import PluggableMap from 'ol/PluggableMap';
// import Control from 'ol/control/Control';


import Select from 'ol/interaction/Select';
//import { SelectEvent } from "ol/interaction/Select";

// Setup the EPSG:27700 (British National Grid) projection.
// proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs");
// register(proj4);


/* const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  view: new View({
    center: [0, 0],
    zoom: 2
  })
}); */


var style_simple = new Style({
  stroke: new Stroke({
    color: '#880000',
    width: 5,
    lineCap: 'round',
  })
});

var style_selected = new Style({
  stroke: new Stroke({
    color: '#ff6600',
    width: 5,
    lineCap: 'round',
  })
});

function simpleStyle(feature) {
  return style_simple;
}
function selectedStyle(feature) {
  return style_selected;
}

const vectorSource = new VectorTile({ //vector source
  format: new MVT(),
  tileGrid: new createXYZ({
    // minZoom: data['minzoom'],
    // maxZoom: data['maxzoom']
    minZoom: 0,
    maxZoom: 50
  }),
  tilePixelRatio: 16,
  //urls: data['tiles']
  //url: 'https://epicbeard.uk/tileserver/tileserver.php?/index.json?/trfgrmpbf/{z}/{x}/{y}.pbf'

  url: 'https://epicbeard.uk/tileserver/tileserver.php?%3f/index.json?/trfgrm-snippet/{z}/{x}/{y}.pbf'
});
const vectorlayer = new VectorTileLayer({ //vector layer
  id: 'routes',
  source: vectorSource,
  //preload: Infinity,
  //extent: ol.proj.transformExtent(data['bounds'], 'EPSG:4326', 'EPSG:3857'),
  // style: function(feature, resolution) {
  //   var layerId = feature.get('layer');
  //   if (!layerStyleVisibility[layerId]) return null;
  //   var style = layerStyleMap[layerId];
  //   return [style];
  // }
  style: simpleStyle,
});



var apiKey = 'M6AnAF3IZSZHT8qty2Rl8aUQhfGJ3hg5';

var serviceUrl = 'https://api.os.uk/maps/raster/v1/zxy';

// Setup the EPSG:27700 (British National Grid) projection.
proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs");
register(proj4);

const proj27700 = getProjection('EPSG:27700');

//const proj3857 = ol.proj.get('EPSG:3857');



var tilegrid = new TileGrid({
  resolutions: [896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75],
  origin: [-238375.0, 1376256.0]
});


// const debugLayer2 = new TileLayer({
//   source: new XYZ({
//     url: serviceUrl + '/Leisure_27700/{z}/{x}/{y}.png?key=' + apiKey,
//     projection: 'EPSG:27700',
//     tileGrid: tilegrid
// }),
//   visible: false,
// });



const osmSource = new OSM();

const debugLayer = new TileLayer({
  source: new TileDebug({
    tileGrid: tilegrid,
    projection: 'EPSG:3857',
    projection: 'EPSG:27700',
  }),
  visible: true,
});

const graticule = new Graticule({
  // the style to use for the lines, optional.
  strokeStyle: new Stroke({
    color: 'rgba(255,120,0,0.9)',
    width: 2,
    lineDash: [0.5, 4],
  }),
  showLabels: true,
  visible: false,
  wrapX: false,
});

const map = new Map({
  layers: [
    new TileLayer({
      source: new XYZ({
        url: serviceUrl + '/Leisure_27700/{z}/{x}/{y}.png?key=' + apiKey,
        projection: 'EPSG:27700',
        tileGrid: tilegrid
      }),
    }),
    debugLayer,
    graticule,
    vectorlayer
  ],
  target: 'map',
  view: new View({

    //projection: 'EPSG:27700',
    //center: [457066, 276524],
    projection: 'EPSG:3857',
    //center: [2, 7000000],//var center = ol.proj.transform([0, 0], 'EPSG:4326', 'EPSG:3857');
    center: new transform([450000, 300000], 'EPSG:27700', 'EPSG:3857'),
    //center: fromLonLat([53.101191 , -1.5798520]),
    zoom: 8,
  }),
});
//map.addLayer(vectorlayer);


const queryInput = document.getElementById('epsg-query');
const searchButton = document.getElementById('epsg-search');
const resultSpan = document.getElementById('epsg-result');
const renderEdgesCheckbox = document.getElementById('render-edges');
const showTilesCheckbox = document.getElementById('show-tiles');
const showGraticuleCheckbox = document.getElementById('show-graticule');

function setProjection(code, name, proj4def, bbox) {
  if (code === null || name === null || proj4def === null || bbox === null) {
    resultSpan.innerHTML = 'Nothing usable found, using EPSG:3857...';
    map.setView(
      new View({
        projection: 'EPSG:3857',
        center: [0, 0],
        zoom: 1,
      })
    );
    return;
  }

  resultSpan.innerHTML = '(' + code + ') ' + name;

  const newProjCode = 'EPSG:' + code;
  proj4.defs(newProjCode, proj4def);
  register(proj4);
  const newProj = getProjection(newProjCode);
  const fromLonLat = getTransform('EPSG:4326', newProj);

  let worldExtent = [bbox[1], bbox[2], bbox[3], bbox[0]];
  newProj.setWorldExtent(worldExtent);

  // approximate calculation of projection extent,
  // checking if the world extent crosses the dateline
  if (bbox[1] > bbox[3]) {
    worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
  }
  const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
  newProj.setExtent(extent);
  const newView = new View({
    projection: newProj,
  });
  map.setView(newView);
  newView.fit(extent);
}

function search(query) {
  resultSpan.innerHTML = 'Searching ...';
  fetch('https://epsg.io/?format=json&q=' + query)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      const results = json['results'];
      if (results && results.length > 0) {
        for (let i = 0, ii = results.length; i < ii; i++) {
          const result = results[i];
          if (result) {
            const code = result['code'];
            const name = result['name'];
            const proj4def = result['proj4'];
            const bbox = result['bbox'];
            if (
              code &&
              code.length > 0 &&
              proj4def &&
              proj4def.length > 0 &&
              bbox &&
              bbox.length == 4
            ) {
              setProjection(code, name, proj4def, bbox);
              return;
            }
          }
        }
      }
      setProjection(null, null, null, null);
    });
}


/**
 * Handle click event.
 * @param {Event} event The event.
 */
searchButton.onclick = function (event) {
  search(queryInput.value);
  event.preventDefault();
};

/**
 * Handle checkbox change events.
 */
renderEdgesCheckbox.onchange = function () {
  osmSource.setRenderReprojectionEdges(renderEdgesCheckbox.checked);
};
showTilesCheckbox.onchange = function () {
  debugLayer.setVisible(showTilesCheckbox.checked);
};
showGraticuleCheckbox.onchange = function () {
  graticule.setVisible(showGraticuleCheckbox.checked);
};

var propertyList = document.getElementById('propertyList');
map.on('pointermove', function (e) {
  propertyList.innerHTML = '';
  map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
    var html = '';
    var props = feature.getProperties();
    for (var key in props) {
      html += '<b>' + key + '</b>: ' + props[key] + '<br />';
    }
    propertyList.innerHTML = html;
    return true;
  });
});
var sizes = map.getSize(); //units:pixels; columns x rows
console.log("getSize (pixels): " + sizes); //2 numbers
// get `extent` through getView()
var extent = map.getView().calculateExtent(map.getSize());
console.log("Extent, LL_x: " + extent[0]); //left
console.log("Extent, LL_y: " + extent[1]); //bottom
console.log("Extent, UR_x: " + extent[2]); //right
console.log("Extent, UR_y: " + extent[3]); //top

// //test
var select = new Select( {
  condition: click,
  //toggleCondition: shiftKeyOnly,
    layers: function (layer) {
      return layer.get('id') == 'routes';
    },
      //style: selectedStyle
  
      style: selectedStyle
} );

// //map.getInteractions().extend([selectInteraction]);

// // Add it to your map
// map.addInteraction(selectInteraction);

// // Here's the event handler that will give you the selected features
// // selectInteraction.on('select', function(e) {
// //     console.log(e.target.getFeatures())
// // })

// selectInteraction.on('click', function (evt) {
  
//   console.log(evt.target.getFeatures())
//   var selected = evt.selected;
//   var deselected = evt.deselected;

//   selected.forEach(function(feature){
//       feature.setStyle(selectedStyle);
//   });

//   deselected.forEach(function(feature){
//       feature.setStyle(null);
//   });
// });




// Add it to your map
map.addInteraction(select);

// Here's the event handler that will give you the selected features
select.on('select', function(e) {
    console.log(e.target.getFeatures())
})